@font-face {
  font-family: 'elenasy-icons';
  src:  url('fonts/elenasy-icons.eot?wiqg2a');
  src:  url('fonts/elenasy-icons.eot?wiqg2a#iefix') format('embedded-opentype'),
    url('fonts/elenasy-icons.ttf?wiqg2a') format('truetype'),
    url('fonts/elenasy-icons.woff?wiqg2a') format('woff'),
    url('fonts/elenasy-icons.svg?wiqg2a#elenasy-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.el {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'elenasy-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.el-cohort:before {
  content: "\e93b";
}
.el-video:before {
  content: "\e911";
}
.el-doc:before {
  content: "\e91a";
}
.el-file:before {
  content: "\e91a";
}
.el-mute:before {
  content: "\e939";
}
.el-volume-down:before {
  content: "\e93a";
}
.el-radio-button:before {
  content: "\e937";
}
.el-volume-full:before {
  content: "\e938";
}
.el-image:before {
  content: "\e935";
}
.el-users-three-fill:before {
  content: "\e936";
}
.el-check-circle-fill:before {
  content: "\e934";
}
.el-trophy:before {
  content: "\e932";
}
.el-gear:before {
  content: "\e933";
}
.el-forward:before {
  content: "\e931";
}
.el-rewind:before {
  content: "\e930";
}
.el-certificate:before {
  content: "\e926";
}
.el-news-lg:before {
  content: "\e928";
}
.el-file-fill:before {
  content: "\e92f";
}
.el-info-circle-fill:before {
  content: "\e92e";
}
.el-share:before {
  content: "\e92d";
}
.el-arrow-left-lg:before {
  content: "\e929";
}
.el-arrow-right-lg:before {
  content: "\e92a";
}
.el-arrow-down-lg:before {
  content: "\e92c";
}
.el-arrow-up-lg:before {
  content: "\e92b";
}
.el-chat-fill:before {
  content: "\e927";
}
.el-check-lg:before {
  content: "\e925";
}
.el-chart:before {
  content: "\e91b";
}
.el-broadcast:before {
  content: "\e913";
}
.el-chevron-down:before {
  content: "\e90f";
}
.el-chevron-up:before {
  content: "\e914";
}
.el-chevron-right:before {
  content: "\e918";
}
.el-chevron-left:before {
  content: "\e919";
}
.el-minus-circle:before {
  content: "\e90a";
}
.el-plus-circle:before {
  content: "\e90d";
}
.el-plus:before {
  content: "\e90e";
}
.el-x:before {
  content: "\e907";
}
.el-user-circle:before {
  content: "\e91c";
}
.el-upload:before {
  content: "\e91d";
}
.el-trash-2:before {
  content: "\e91e";
}
.el-mail:before {
  content: "\e91f";
}
.el-logout:before {
  content: "\e920";
}
.el-filter:before {
  content: "\e921";
}
.el-edit:before {
  content: "\e922";
}
.el-check:before {
  content: "\e923";
}
.el-arrow-down:before {
  content: "\e924";
}
.el-arrow-right:before {
  content: "\e901";
}
.el-arrow-up:before {
  content: "\e905";
}
.el-arrow-left:before {
  content: "\e900";
}
.el-bell:before {
  content: "\e902";
}
.el-book:before {
  content: "\e903";
}
.el-chat:before {
  content: "\e904";
}
.el-dot:before {
  content: "\e906";
}
.el-edit-fill:before {
  content: "\e908";
}
.el-eye:before {
  content: "\e909";
}
.el-grid:before {
  content: "\e90b";
}
.el-link:before {
  content: "\e90c";
}
.el-news:before {
  content: "\e910";
}
.el-play-fill-circle:before {
  content: "\e912";
}
.el-search:before {
  content: "\e915";
}
.el-slider:before {
  content: "\e916";
}
.el-trash:before {
  content: "\e917";
}
